

import React, { useEffect, useRef } from 'react';
import getImageURL from 'bwax-ui/getImageURL';

import AutoPlayVideo from "bwax-ui/components/AutoPlayVideo";
import Button from "bwax-ui/components/Button";
import Link from 'bwax-ui/page/Link'


import classNames from 'classnames';

import { useTrack } from 'bwax-ui/track';
import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';



const supportQrCode = "https://bw-dt.oss-cn-shenzhen.aliyuncs.com/public/dt-bwax/design-resources/support-qrcode.jpg";


export default function HomePage_inbot({ data, slots, viewEnv }) {

    const track = useTrack();

    const {
        illustrationPC, illustrationMobile, features, videoDemo,
        tryButtonLabel = "立即体验", tryUrl = "/signup",
    } = data;

    const { routeTo, webEnv } = viewEnv;

    const { articleGrid } = slots;

    const colorText = (t, color = "violet") => {
        return <span className={`text-[var(--${color}11)] font-medium`}>{t}</span>
    }



    function renderSloganAndBanner() {

        // const slogan = <>{colorText("大模型")}时代的{colorText("生产力工具")}</>;
        // const slogan = <>每一个{colorText("知识工作者")}</>
        const slogan = <>使用 {colorText("AI")}，跟你的{colorText("知识")}直接{colorText("对话")}</>
        const captions = (
            <>
                {/* <div>{colorText("每个人")}都是知识工作者</div>
                <div>都需要自己的{colorText("知识库")}</div>
                <div>和基于这个知识库的 {colorText("AI 助手")}</div> */}
                {/* <div>{colorText("知识助手")}已经停止服务</div> */}
                <div>我们致力于打造各种{colorText("应用产品")}</div>
                <div>让{colorText("生成式AI")}用起来更{colorText("简单")}</div>
                <div>让每个人都能{colorText("轻松享受")}技术带来的便利与创新</div>

                {/* <div>帮你{colorText("提速")}，激发你的{colorText("创造力")}</div> */}
            </>
        )

        return (
            <>
                {/* wide */}
                <div className="hidden md:flex gap-4 justify-center items-center h-[480px] w-full max-w-6xl px-6">
                    <div className="w-2/5 flex flex-col gap-12 h-full justify-center items-center min-w-[328px]">
                        <div className="flex flex-col gap-16">
                            <div className="flex flex-col gap-8 pb-20">
                                {/* <h2 className="text-[24px] font-medium leading-normal">{slogan}</h2> */}
                                {/* <div className="font-size-16">{colorText("知识助手")}已经停止服务 :(</div> */}
                                <div className="font-size-16 flex flex-col gap-3 px-1">
                                    {captions}
                                </div>
                            </div>
                            {/* <div className="px-1">
                                <Button id="btn-try-at-banner-wide"
                                    appearance="primary" size="large" className="!px-20" color="violet" onPress={_ => {
                                        // routeTo(tryUrl);
                                        track("home_try", { method: "banner_wide" })
                                        const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
                                        window.open(url);
                                    }}>{tryButtonLabel}</Button>
                            </div> */}
                        </div>
                    </div>
                    <div className="w-3/5 pr-2">
                        <img className="w-full max-w-[640px]" src={getImageURL(illustrationPC, "large")} />
                    </div>
                </div>
                {/* narrow */}
                <div className="flex md:hidden flex-col gap-4 justify-center items-center w-full max-w-6xl px-6 py-4">
                    <div className="w-full flex flex-col gap-16 justify-center items-center pt-8 pb-12">
                        <div className="flex flex-col gap-6">
                            {/* <h2 className="text-[22px] font-medium leading-normal">{slogan}</h2> */}
                            {/* <div className="font-size-15">{colorText("知识助手")}已经停止服务 :(</div> */}
                            <div className="font-size-14 flex flex-col gap-3">
                                {captions}
                            </div>
                        </div>
                        {/* <div>
                            <Button id="btn-try-at-banner-narrow" onPress={_ => {
                                track("home_try", { method: "banner_narrow" })
                                const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
                                window.open(url);
                            }}
                                appearance="primary" size="large" className="!px-20" color="violet">{tryButtonLabel}</Button>
                        </div> */}
                    </div>
                    <div className="py-12">
                        <img className="w-full max-w-[480px]" src={getImageURL(illustrationPC, "large")} />
                    </div>
                </div>
            </>

        )

    }

    const products = [
        [
            "文档翻译",
            ["基于大语言模型，可以翻译 pdf、docx、pptx、epub等十几种主流格式的文件，通过排版分析和还原，让译文文档最大可能地呈现跟原文一样的显示效果。"],
            "https://translate.simplifyai.cn/?ref=kb-list"
        ]
    ]

    function renderProducts() {
        return (
            <div className="flex w-full max-w-[40rem] flex-col gap-12 items-center pt-20 pb-40 px-4">
                {/* <h2 className='font-medium font-size-22'>
                    主要产品
                </h2> */}
                {products.map(p => {
                    const [title, descs, link] = p;
                    return (
                        <Link key={title} to={link}>
                            <div className="flex flex-col rounded-lg px-6 py-8 hover:shadow shadow-sm bg-[--slate2] gap-4">
                                <div className="flex w-full pr-4 gap-2 items-center  hover:underline">
                                    <h3 className="font-size-20 text-[--violet11] ">{title}</h3>
                                    <div className=" text-[--violet11]">{">>>"}</div>
                                </div>
                                <div>
                                    {descs.map(d => (
                                        <div key={d}>
                                            {d}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="pt-28 w-full flex flex-col items-center">
            {renderSloganAndBanner()}
            {renderProducts()}
        </div>
    )
}


